import React, {Component} from 'react';
import Page500 from '../views/Pages/Page500';
import {htmlErrorHandler} from "./tools";
import Spinner from "./spinner";

class ErrorBoundary extends Component {
    constructor (props) {
        super(props);
        this.state = {
            hasError: false,
            errorObject: null,
            loading: false
        }

        this.isCancelled = false;
        this.unlisten = null;
    }



    componentWillUnmount () {
        this.isCancelled = true;
    }

    static getDerivedStateFromError () {
        return {hasError: true};
    }

    componentDidCatch (error) {
        const {errorObject} = htmlErrorHandler(error, 'caught in error boundary');
        // grab out errors caused by frontend refresh live during use and just reload the page instead
        if (error.message && error.message.match(/Loading chunk/i)) {
            // override all error screens if planning to refresh page
            !this.isCancelled && this.setState({loading: true});
            window.location.reload();
        } else {
            !this.isCancelled && this.setState({hasError: true, errorObject});
        }
    }

    render () {
        if (this.state.loading) {
            return <Spinner />
        } else if (this.state.hasError) {
            return <Page500 error={this.state.errorObject}/>
        } else {
            return this.props.children;
        }
    }
}

export default ErrorBoundary;
