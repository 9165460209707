import * as ActionTypes from './actionTypes';
import axios from 'axios';
import {error} from '../../utils/tools';

const loggingIn = () => ({
    type: ActionTypes.LOGGING_IN
});

const loginSuccess = (user) => ({
    type: ActionTypes.LOGIN_SUCCESS,
    payload: user
});

export const loginFailure = (error) => ({
    type: ActionTypes.LOGIN_FAILURE,
    payload: error
});

export const login = (user) => (dispatch) => {
    dispatch(loggingIn());
    let authentication;

    axios.post('/exp/user/login', {username: user.username, password: user.password})
        .then(res => {
            authentication = res.data;

            return axios.get('/exp/personalization/calendar-options');
        })
        .then((r) => {

            dispatch(setCalendarOptions(r.data));
            dispatch(loginSuccess(authentication));
        })
        .catch(err => {
            error(err);
            dispatch(loginFailure(err));
        });
};

const logoutSuccess = () => ({
    type: ActionTypes.LOGOUT_SUCCESS
});

export const logout = () => (dispatch) => {
    axios.get('/exp/user/single-logout')
        .then(() => {
            dispatch(logoutSuccess());
        })
        .catch(err => {
            error(err);
            dispatch(logoutSuccess());
        })
};

const setCalendarOptions = (options) => ({
    type: ActionTypes.SET_CALENDAR_OPTIONS,
    payload: options
});

export const fetchCalendarOptions = () => (dispatch) => {
    axios.get('/exp/personalization/calendar-options')
        .then((r) => {
            dispatch(setCalendarOptions(r.data));
        })
        .catch(error);
}

export const fetchAuthentication = () => (dispatch) => {
    dispatch(loggingIn());
    let authentication;
    axios.get('/exp/user')
        .then(r => {
            authentication = r.data;

            return axios.get('/exp/personalization/calendar-options');
        })
        .then((r) => {

            dispatch(setCalendarOptions(r.data));
            dispatch(loginSuccess(authentication));
        })
        .catch(err => {
            error(err);
            dispatch(logoutSuccess());
        });
};

export const selectPractice = (practiceId) => ({
    type: ActionTypes.SELECT_PRACTICE,
    payload: practiceId
});
