import axios from 'axios';

export const getMyTicks = () => (new Promise((resolve, reject) => {
    axios.get('/exp/tick/general/my').then(res => resolve(res.data)).catch(reject);
}));

export const uploadDocument = (body) => {
    return new Promise((resolve, reject) => {
        axios.post('/exp/visit-detail/document/upload', body, {headers: {'content-type': 'multipart/form-data'}})
            .then(resolve).catch(reject);
    });
};

export const createTick = (tick) => new Promise((resolve, reject) => {
    axios.post('/exp/tick/general/create', tick, {headers: {'content-type': 'multipart/form-data'}})
        .then(res => resolve(res.data))
        .catch(reject);
});

export const createTickNoScreenshot = (tick) => new Promise((resolve, reject) => {
    axios.post('/exp/tick/general/create-no-screenshot', tick)
        .then(res => resolve(res.data))
        .catch(reject);
});

