// for use with permissions targets
import {
    faCalendar,
    faCalendarTimes,
    faClock,
    faDollarSign,
    faPhone,
    faStar, faTimes
} from "@fortawesome/free-solid-svg-icons";

export const PROVIDERSCHEDULE = 'ProviderSchedule';
export const PRACTICESCHEDULE = 'PracticeSchedule';
export const PRACTICELIST = 'PracticeList';
export const CHANGEREQUEST = 'ChangeRequest';
export const EMPLOYEEFEATURE = 'EmployeeFeature';
export const PRACTICEPAGE = 'PracticePage';
export const USER_MANAGEMENT = 'UserManagement';
export const BREED_MANAGEMENT = 'BreedManagement';
export const REFERRAL_OPTION_TYPES_MANAGEMENT = 'ReferralOptionTypesManagement';
export const INACTIVE_REASON_MANAGEMENT = 'InactiveReasonManagement';
export const TICK_MANAGEMENT = 'TickManagement';
export const ADMINMENU = 'AdminMenu';
export const GCAL = 'Gcal';
export const ADMINISTRATIVEREPORTS = 'AdministrativeReports';
export const PRACTICECONNECTIONS = 'PracticeConnections';
export const PRACTICESETTINGS = 'PracticeSettings';
export const URNREPORT = 'UrnReport';
export const EMAILTEMPLATES = 'EmailTemplates';
export const CUSTOMARTICLES = 'CustomArticles';
export const GENERALPRACTICESETTINGS = 'GeneralPracticeSettings';
export const VISITDETAILS = 'VisitDetails';
export const KNOWLEDGEBASE = 'KnowledgeBase';
export const CUSTOMCHECKLIST = 'CustomChecklist';
export const EDITLOCKEDINVOICE = 'EditLockedInvoice';
export const REMOVEFROMINCOMPLETE = 'RemoveFromIncomplete';
export const CUSTOMREPORT = 'CustomReport';
export const MERGE = 'Merge';
export const DVMCFEES_MANAGEMENT = 'DvmcFeesManagement';
export const RECENT_ACTIVITY = 'RecentActivity';
export const SUGGESTIONS = 'Suggestions';

// for use with permissions action
export const READ = 'read';
export const USE = 'use';
export const SUBSCRIBE = 'subscribe';

export const timezones = [
    'America/New_York',
    'America/Chicago',
    'America/Denver',
    'America/Los_Angeles',
    'US/Alaska',
    'US/Arizona',
    'US/Aleutian',
    'US/East-Indiana',
    'US/Indiana-Starke',
    'US/Michigan',
    'Canada/Atlantic',
    'Canada/Central',
    'Canada/Eastern',
    'Canada/Mountain',
    'Canada/Newfoundland',
    'Canada/Pacific',
    'Canada/Saskatchewan',
    'Canada/Yukon'
];

export const visitStatuses = [
    'Pending',
    'Scheduled',
    'Cancelled',
    'Occurred',
    'Inactive'
];

export const urnStatuses = [
    'Pre-office', // called crematory
    'STAR', // called pre-delivery
    'Highlander',
    'Sienna 1',
    'Sienna 2',
    'RAV4',
    'Post-delivery',
    'Complete', // called contact complete
    'BLDG', // called office
    'Abandoned',
    'Scattered'
];

export const urnStatusesDoor = [
    'Pre-office',
    'Complete'
];

export const serviceTypes = [
    'Main Service',
    'Aftercare',
    'Ash Return',
    'After Hours Fee',
    'Discount',
    'Other',
    'Travel Fee'
];

export const serviceCategories = [
    'EUTH',
    'HOSP',
    'CREM',
    'DISC',
    'FEES',
    'MISC',
    'ACUP',
    'PICK',
    'TRAV'
];

export const countryCodes = [
    'USA',
    'CAN'
];

const visitTemplateKey = [
    {
        key: '$allPets',
        value: 'grammatical list of all pets (ex: "Buddy and Fluffy" or "Buddy, Fluffy, and Wilson")'
    },
    {
        key: '$pet',
        value: 'first pet\'s name'
    },
    {
        key: '$gender',
        value: 'first pet\'s gender'
    },
    {
        key: '$species',
        value: 'first pet\'s species'
    },
    {
        key: '$weight',
        value: 'first pet\'s weight (in pounds)'
    },
    {
        key: '$age',
        value: 'first pet\'s age (in years)'
    },
    {
        key: '$petSentences',
        value: 'descriptive sentences for each pet in the form of "Buddy is a 13 pound, male dog." separated by 2 carriage returns (you should precede and follow this merge field with 1 or more carriage returns)'
    },
    {
        key: '$referring',
        value: 'comma separated list of names of first pet\'s referring vets'
    },
    {
        key: '$firstReferring',
        value: 'the name of the first pet\'s first listed referring vet'
    },
    {
        key: '$client',
        value: 'client\'s name'
    },
    {
        key: '$greetingName',
        value: 'client\'s first name(s), can only be edited for clients of practices which have enabled Greeting Names in Practice Settings'
    },
    {
        key: '$phone',
        value: 'comma separated list of client\'s phone numbers'
    },
    {
        key: '$address',
        value: 'client\'s street address (with unit number)',
    },
    {
        key: '$city',
        value: 'client\'s city',
    },
    {
        key: '$state',
        value: 'client\'s state (two letter code)',
    },
    {
        key: '$zip',
        value: 'client\'s zip code',
    },
    {
        key: '$onlinePaymentUrl',
        value: 'a url that will direct to the pay online form (can only be used for practices with Square integration)'
    },
    {
        key: '$consentFormUrl',
        value: 'a url that will direct to the online consent form (can only be used for practices with a consent form configured in Cognito Forms)'
    },
    {
        key: '$visitGuid',
        value: 'the GUID of the visit (for use with making custom links)'
    },
    {
        key: '$shortDate',
        value: 'the date of the visit in the format of M/D/YYYY'
    },
    {
        key: '$longDate',
        value: 'the date of the visit in the format Monday, September 9th'
    },
    {
        key: '$time',
        value: 'the time of the appointment in the format h:mm am'
    },
    {
        key: '$provider',
        value: 'the primary provider\'s name'
    },
    {
        key: '$payment',
        value: 'total payments made in the format of $115.00'
    },
    {
        key: '$invoiceTotal',
        value: 'total cost of visit in the format of $115.00'
    },
    {
        key: '$balanceDue',
        value: 'total amount still due in the format of $115.00'
    },
    {
        key: '$aftercare',
        value: 'name of aftercare service selected or "No aftercare" if not selected'
    },
    {
        key: '$ashReturn',
        value: 'name of ash return service selected or "No ash return" if not selected'
    },
    {
        key: '$signatureImage',
        value: 'image of a hand-written signature'
    },
    {
        key: '$signature',
        value: 'default practice signature'
    },
    {
        key: '$logoSignature',
        value: 'default practice signature with square logo to left and separated by vertical bar'
    }
];

const referringTemplateKey = [
    {
        key: '$pet',
        value: 'pet\'s name'
    },
    {
        key: '$gender',
        value: 'pet\'s gender'
    },
    {
        key: '$species',
        value: 'pet\'s species'
    },
    {
        key: '$weight',
        value: 'pet\'s weight (in pounds)'
    },
    {
        key: '$age',
        value: 'pet\'s age (in years)'
    },
    {
        key: '$client',
        value: 'client\'s name'
    },
    {
        key: '$greetingName',
        value: 'client\'s first name(s), can only be edited for clients of practices which have enabled Greeting Names in Practice Settings'
    },
    {
        key: '$phone',
        value: 'comma separated list of client\'s phone numbers'
    },
    {
        key: '$address',
        value: 'client\'s street address (with unit number)',
    },
    {
        key: '$city',
        value: 'client\'s city',
    },
    {
        key: '$state',
        value: 'client\'s state (two letter code)',
    },
    {
        key: '$zip',
        value: 'client\'s zip code',
    },
    {
        key: '$signature',
        value: 'default practice signature'
    },
    {
        key: '$logoSignature',
        value: 'default practice signature with square logo to left and separated by vertical bar'
    },
    {
        key: '$signatureImage',
        value: 'image of a hand-written signature'
    },
    {
        key: '$visitGuid',
        value: 'the GUID of the visit (for use with making custom links)'
    },
    {
        key: '$shortDate',
        value: 'the date of the visit in the format of M/D/YYYY'
    },
    {
        key: '$longDate',
        value: 'the date of the visit in the format Monday, September 9th'
    },
    {
        key: '$time',
        value: 'the time of the appointment in the format h:mm am'
    },
    {
        key: '$provider',
        value: 'the primary provider\'s name'
    },
    {
        key: '$aftercare',
        value: 'name of aftercare service selected or "No aftercare" if not selected'
    },
    {
        key: '$ashReturn',
        value: 'name of ash return service selected or "No ash return" if not selected'
    },
    {
        key: '$referring',
        value: 'referring practice name'
    }
];

const receiptTemplateKey = [
    ...visitTemplateKey.filter(el => el.key !== '$payment'),
    {
        key: '$payment',
        value: 'amount of selected payment in the format of $115.00 (negative values will appear as positive if used in the Refund template only)'
    },
    {
        key: '$paymentType',
        value: 'payment method'
    },
    {
        key: '$paymentDate',
        value: 'date of payment made in the format of M/D/YYYY'
    }
];

export const emailTemplateKey = {
    'Visit Confirmation': visitTemplateKey,
    'Visit Confirmation (same-day)': visitTemplateKey,
    'Referring Notification': referringTemplateKey,
    'Post Visit Grief Email': visitTemplateKey,
    'Anniversary Email': visitTemplateKey,
    'Reminder Email': visitTemplateKey,
    'Receipt': receiptTemplateKey,
    'Refund': receiptTemplateKey,
    'Cancellation': visitTemplateKey,
    'Reschedule': visitTemplateKey,
    'Invoice': visitTemplateKey,
    'Records': visitTemplateKey,
    Other: visitTemplateKey
};

export const customChecklistTypes = [
    'COVID',
    'Behavior',
    'Service',
    'Multiple Pet',
    'Apartment'
];

export const invoiceLockTime = 7; // time in days after the visit at which the invoice locks

export const paymentStatuses = [
    'Received',
    'Verified',
    'Under Review'
];

// for use with custom reports
export const ppasId = '5c7552e00bdecf4388ac9855';
export const mpetId = '5c7550fd0bdecf4388ac984c';
export const quilId = '5d768bf5c33ccf2c70ceca65';


export const tickTypes = [
    'bug',
    'feature request'
];

export const tickStatuses = [
    'submitted',
    'under review',
    'added to JIRA',
    'completed',
    'not done'
];

export const cardTypes = [
    'Anniversary',
    'Condolence'
];

export const stopWords = [
    "a",
    "about",
    "above",
    "after",
    "again",
    "against",
    "all",
    "am",
    "an",
    "and",
    "any",
    "are",
    "as",
    "at",
    "be",
    "because",
    "been",
    "before",
    "being",
    "below",
    "between",
    "both",
    "but",
    "by",
    "could",
    "did",
    "do",
    "does",
    "doing",
    "down",
    "during",
    "each",
    "few",
    "for",
    "from",
    "further",
    "had",
    "has",
    "have",
    "having",
    "he",
    "he'd",
    "he'll",
    "he's",
    "her",
    "here",
    "here's",
    "hers",
    "herself",
    "him",
    "himself",
    "his",
    "how",
    "how's",
    "i",
    "i'd",
    "i'll",
    "i'm",
    "i've",
    "if",
    "in",
    "into",
    "is",
    "it",
    "it's",
    "its",
    "itself",
    "let's",
    "me",
    "more",
    "most",
    "my",
    "myself",
    "nor",
    "of",
    "on",
    "once",
    "only",
    "or",
    "other",
    "ought",
    "our",
    "ours",
    "ourselves",
    "out",
    "over",
    "own",
    "same",
    "she",
    "she'd",
    "she'll",
    "she's",
    "should",
    "so",
    "some",
    "such",
    "than",
    "that",
    "that's",
    "the",
    "their",
    "theirs",
    "them",
    "themselves",
    "then",
    "there",
    "there's",
    "these",
    "they",
    "they'd",
    "they'll",
    "they're",
    "they've",
    "this",
    "those",
    "through",
    "to",
    "too",
    "under",
    "until",
    "up",
    "very",
    "was",
    "we",
    "we'd",
    "we'll",
    "we're",
    "we've",
    "were",
    "what",
    "what's",
    "when",
    "when's",
    "where",
    "where's",
    "which",
    "while",
    "who",
    "who's",
    "whom",
    "why",
    "why's",
    "with",
    "would",
    "you",
    "you'd",
    "you'll",
    "you're",
    "you've",
    "your",
    "yours",
    "yourself",
    "yourselves"
];

export const maxFileUploadSize = 50000000;

export const defaultSympathyCharacterLimit = 10000;

export const daysOfWeek = [
    {value: '0', text: 'Sunday'},
    {value: '1', text: 'Monday'},
    {value: '2', text: 'Tuesday'},
    {value: '3', text: 'Wednesday'},
    {value: '4', text: 'Thursday'},
    {value: '5', text: 'Friday'},
    {value: '6', text: 'Saturday'},
];

export const recentActivityTypes = {
    viewContactLog: 'viewed contact log',
    submitContactForm: 'submitted contact form',
    viewVisitDetail: 'viewed visit detail',
    updateVisitDetail: 'updated data in visit detail',
    addMr: 'added medical record',
    addSympathy: 'added sympathy sentiment',
    addPayment: 'added payment',
    addTask: 'added task',
    completeTask: 'completed task',
    sendEmail: 'sent email',
    cancelVisit: 'cancelled visit',
    rescheduleVisit: 'rescheduled visit',
    scheduleVisit: 'scheduled visit',
    visitCompletion: 'performed visit completion'
};

// begin calendar options enum
export const deviceTypes = {
    mobile: 'Mobile Phone',
    tablet: 'Tablet',
    desktop: 'Desktop / Laptop'
};

export const buddyCalendars = {
    scheduling: 'Scheduling Calendar',
    main: 'Main Calendar'
};

export const schedulingCalendarViews = {
    provider: 'Provider View',
    suggestion: 'Suggestion View',
    day: 'One Day View',
    threeDay: 'Three Day View',
    week: 'Week View'
}

export const mainCalendarViews = {
    provider: 'Provider View',
    day: 'One Day View',
    threeDay: 'Three Day View',
    week: 'Week View'
}

export const eventTypes = {
    slot: 'slot',
    visit: 'visit',
    selfSchedule: 'selfSchedule',
    reservation: 'reservation',
    allDayNote: 'allDayNote'
}
// end calendar options enum

// begin main calendar colors
export const selfScheduleColor = {
    backgroundColor: '#499fe8',
    borderColor: '#1b466b',
    color: '#000000'
};

export const specialFeeColor = {
    backgroundColor: '#fffb85',
    borderColor: '#a6a35e',
    color: '#000000'
};

export const virtualColor = {
    backgroundColor: '#e89dfb',
    borderColor: '#862c9c',
    color: '#000000'
};

export const blockedOffColor = {
    backgroundColor: '#FF5074',
    borderColor: '#a12f46',
    color: '#000000'
};

export const availableColor = {
    backgroundColor: '#C8C8C8',
    borderColor: '#828282',
    color: '#000000'
};

export const confirmedColor = {
    backgroundColor: '#1bc43d',
    borderColor: '#0d7222',
    color: '#000000'
};

export const unconfirmedColor = {
    backgroundColor: '#ff913d',
    borderColor: '#c6712f',
    color: '#000000'
};

export const allDayNoteColor = {
    backgroundColor: '#99258B',
    borderColor: '#781D6D',
    color: '#fff'
};

export const calendarColors = {
    selfSchedule: selfScheduleColor.backgroundColor,
    specialFee: specialFeeColor.backgroundColor,
    virtual: virtualColor.backgroundColor,
    blockedOff: blockedOffColor.backgroundColor,
    available: availableColor.backgroundColor,
    confirmed: confirmedColor.backgroundColor,
    unconfirmed: unconfirmedColor.backgroundColor,
    allDayNote: allDayNoteColor.backgroundColor
};
// end main calendar colors

// begin suggestion calendar colors
export const greatMatch = {
    backgroundColor: '#1bc43d',
    borderColor: '#0d7222',
    color: '#000000'
};

export const goodMatch = {
    backgroundColor: '#fffb85',
    borderColor: '#a6a35e',
    color: '#000000'
};

export const moderateMatch = {
    backgroundColor: '#ff913d',
    borderColor: '#c6712f',
    color: '#000000'
};

export const poorMatch = {
    backgroundColor: '#FF5074',
    borderColor: '#a12f46',
    color: '#000000'
};

export const filterOut = {
    backgroundColor: '#494949',
    borderColor: '#383838',
    color: '#fff'
};

export const suggestionUnavailable = {
    backgroundColor: '#499fe8',
    borderColor: '#1b466b',
    color: '#000000'
};

export const nonSuggestionEntity = {
    backgroundColor: '#C8C8C8',
    borderColor: '#b7b7b7',
    color: '#fff'
};

export const suggestionColors = {
    greatMatch: greatMatch.backgroundColor,
    goodMatch: goodMatch.backgroundColor,
    moderateMatch: moderateMatch.backgroundColor,
    poorMatch: poorMatch.backgroundColor,
    filterOut: filterOut.backgroundColor,
    suggestionUnavailable: suggestionUnavailable.backgroundColor,
    nonSuggestionEntity: nonSuggestionEntity.backgroundColor
};

export const suggestionBadges = {
    visit: {
        style: {
            backgroundColor: confirmedColor.backgroundColor,
            color: '#fff'
        },
        icon: faCalendar
    },
    selfSchedule: {
        style: {
            backgroundColor: selfScheduleColor.backgroundColor,
            color: '#fff'
        },
        icon: faCalendar
    },
    reservation: {
        style: {
            backgroundColor: unconfirmedColor.backgroundColor,
            color: '#fff'
        },
        icon: faCalendarTimes
    },
    thisVisitReservation: {
        style: {
            backgroundColor: greatMatch.backgroundColor,
            color: '#fff'
        },
        icon: faStar

    },
    outOfRange: {
        style: {
            backgroundColor: '#000',
            color: '#fff'
        },
        icon: faClock
    },
    virtual: {
        style: {
            backgroundColor: virtualColor.backgroundColor,
            color: '#fff'
        },
        icon: faPhone
    },
    specialFee: {
        style: {
            backgroundColor: '#efe400',
            color: '#fff'
        },
        icon: faDollarSign
    },
    blockedOff: {
        style: {
            backgroundColor: '#000',
            color: '#fff'
        },
        icon: faTimes
    }
};

// end suggestion calendar colors
