import * as ActionTypes from "./actionTypes";

export const authentication = (
    state = {
        loggedIn: false,
        loggingIn: false,
        user: {},
        loginError: null
    },
    action
) => {
    switch (action.type) {
        case ActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                user: action.payload,
                loggingIn: false,
                loginError: null
            };
        case ActionTypes.LOGIN_FAILURE:
            return {
                ...state,
                loggedIn: false,
                user: {},
                loggingIn: false,
                loginError: action.payload
            };
        case ActionTypes.LOGGING_IN:
            return {
                ...state,
                loggedIn: false,
                loggingIn: true,
                user: {},
                loginError: null
            };
        case ActionTypes.LOGOUT_SUCCESS:
            return {
                ...state,
                loggedIn: false,
                user: {},
                loggingIn: false,
                loginError: null
            };
        default:
            return state;
    }
};

export const practiceSettings = (
    state = {
        currentPractice: null
    },
    action
) => {
    switch(action.type) {
        case ActionTypes.SELECT_PRACTICE:
            return {
                ...state,
                currentPractice: action.payload
            }
        default:
            return state;
    }
}

export const personalization = (
    state = {
        calendarOptions: []
    },
    action
) => {
    switch(action.type) {
        case ActionTypes.SET_CALENDAR_OPTIONS:
            return {
                ...state,
                calendarOptions: action.payload
            }
        default:
            return state;
    }
}
