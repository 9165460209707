import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {fetchAuthentication} from './redux/authentication/actionCreators';
import Spinner from './utils/spinner';
import './App.scss';
import './Spinners.scss';
import {AbilityContext} from './utils/acl/ability-context';
import ability from './utils/acl/abilities';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import {error} from './utils/tools';
import ErrorBoundary from "./utils/ErrorBoundary";


const loading = () => <div className="animated fadeIn pt-3 text-center"><Spinner/></div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const ForgotPassword = React.lazy(() => import('./views/Pages/ForgotPassword/ForgotPassword'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const SquareSuccessPage = React.lazy(() => import('./views/Pages/SquareSuccessPage'));
const GmailSuccessPage = React.lazy(() => import('./views/Pages/GmailSuccessPage'));

const mapStateToProps = state => ({
    authentication: state.authentication,
    calendarOptions: state.calendarOptions
});

const mapDispatchToProps = dispatch => ({
    fetchAuthentication: () => dispatch(fetchAuthentication())
});

class App extends Component {
    constructor (props) {
        super(props);

        this.isCancelled = false;
    }

    componentWillUnmount () {
        this.isCancelled = true;
    }

    componentDidMount () {
        this.props.fetchAuthentication();
        const that = this;

        let sqPaymentScript = document.createElement('script');
        sqPaymentScript.src = process.env.REACT_APP_SQUARE_URL;
        sqPaymentScript.type = "text/javascript"
        sqPaymentScript.async = false;
        sqPaymentScript.onload = () => {
            that.setState({
                squareLoaded: true
            })
        };
        document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);

        // ping server for downpage every 5 minutes
        setInterval(() => {
            axios.get('/exp/notifications/check-downpage')
                .then(res => {
                    if (res.data !== null) {
                        window.location && window.location.reload();
                    }
                })
                .catch(error);
        }, 1000 * 60 * 5);
    }

    render () {
        return (
            <AbilityContext.Provider value={ability(this.props.authentication.user)}>
                <ErrorBoundary>
                    <BrowserRouter>
                        <React.Suspense fallback={loading()}>
                            <Switch>
                                <Route exact path="/forgot-password/:jwt" name="Forgot Password"
                                       render={props => <ForgotPassword {...props} />}/>
                                <Route exact path="/success" name="Success"
                                       render={props => <SquareSuccessPage {...props} />}/>
                                <Route exact path="/gmail/success" name="Gmail Success"
                                       render={props => <GmailSuccessPage {...props} />}/>
                                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />}/>
                                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />}/>
                                <Route path="/" name="Home" render={props => <DefaultLayout {...props} />}/>
                            </Switch>
                        </React.Suspense>
                    </BrowserRouter>
                </ErrorBoundary>
            </AbilityContext.Provider>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
