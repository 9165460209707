import {
    PROVIDERSCHEDULE,
    CHANGEREQUEST,
    PRACTICESCHEDULE,
    PRACTICELIST,
    GCAL,
    READ,
    USE,
    SUBSCRIBE,
    PRACTICEPAGE,
    ADMINMENU,
    ADMINISTRATIVEREPORTS,
    PRACTICECONNECTIONS,
    PRACTICESETTINGS,
    URNREPORT,
    USER_MANAGEMENT,
    INACTIVE_REASON_MANAGEMENT,
    EMAILTEMPLATES,
    GENERALPRACTICESETTINGS,
    VISITDETAILS,
    KNOWLEDGEBASE,
    CUSTOMCHECKLIST,
    EDITLOCKEDINVOICE,
    REMOVEFROMINCOMPLETE,
    CUSTOMREPORT,
    TICK_MANAGEMENT,
    MERGE,
    BREED_MANAGEMENT,
    CUSTOMARTICLES,
    DVMCFEES_MANAGEMENT,
    RECENT_ACTIVITY,
    REFERRAL_OPTION_TYPES_MANAGEMENT,
    EMPLOYEEFEATURE, SUGGESTIONS
} from '../constants';

// this list of roles needs to be separately maintained in the ENUM on the User model in our server
export const permissions = user => ({

    authenticatedUser: {
        inherits: [],
        can: [
            [READ, EMAILTEMPLATES, {practice: user.practice}],
            [USE, VISITDETAILS, {practice: user.practice}],
            [READ, KNOWLEDGEBASE, {practice: user.practice}],
            [READ, CUSTOMCHECKLIST, {practice: user.practice}],
            [READ, CUSTOMARTICLES, {practice: user.practice}]
        ]
    },
    provider: {
        inherits: ['authenticatedUser'],
        can: [
            [READ, PROVIDERSCHEDULE, {_id: user._id}],
            [SUBSCRIBE, GCAL]
        ]
    },
    employee: {
        inherits: ['authenticatedUser'],
        can: [
            [USE, CHANGEREQUEST],
            [USE, EMPLOYEEFEATURE],
            [USE, URNREPORT]
        ]
    },
    practiceScheduler: {
        inherits: ['authenticatedUser'],
        can: [
            [READ, PROVIDERSCHEDULE, {practice: user.practice}],
            [READ, PRACTICESCHEDULE]
        ]
    },
    viewSuggestions: {
        inherits: ['authenticatedUser'],
        can: [
            [READ, SUGGESTIONS]
        ]
    },
    dvmcStaff: {
        inherits: ['employee', 'practiceScheduler'],
        can: [
            [READ, PRACTICELIST],
            [READ, PROVIDERSCHEDULE],
            [USE, VISITDETAILS],
            [READ, EMAILTEMPLATES],
            [READ, KNOWLEDGEBASE],
            [READ, CUSTOMCHECKLIST],
            [READ, CUSTOMARTICLES]
        ]
    },
    administrator: {
        inherits: ['dvmcStaff'],
        can: [
            [USE, PRACTICEPAGE],
            [USE, USER_MANAGEMENT],
            [USE, ADMINMENU],
            [READ, ADMINISTRATIVEREPORTS],
            [USE, PRACTICECONNECTIONS],
            [READ, PRACTICESETTINGS],
            [USE, EMAILTEMPLATES],
            [USE, GENERALPRACTICESETTINGS],
            [READ, CUSTOMCHECKLIST],
            [USE, CUSTOMCHECKLIST],
            [READ, CUSTOMCHECKLIST],
            [USE, EDITLOCKEDINVOICE],
            [USE, REMOVEFROMINCOMPLETE],
            [USE, CUSTOMREPORT],
            [USE, TICK_MANAGEMENT],
            [USE, INACTIVE_REASON_MANAGEMENT],
            [USE, MERGE],
            [USE, BREED_MANAGEMENT],
            [USE, REFERRAL_OPTION_TYPES_MANAGEMENT],
            [USE, CUSTOMARTICLES],
            [USE, DVMCFEES_MANAGEMENT],
            [READ, RECENT_ACTIVITY]
        ]
    },
    practiceAdministrator: {
        inherits: ['authenticatedUser', 'practiceScheduler'],
        can: [
            [READ, ADMINISTRATIVEREPORTS, {practice: user.practice}],
            [USE, PRACTICECONNECTIONS, {practice: user.practice}],
            [READ, PRACTICESETTINGS, {practice: user.practice}],
            [USE, EMAILTEMPLATES, {practice: user.practice}],
            [USE, GENERALPRACTICESETTINGS, {practice: user.practice}],
            [READ, CUSTOMCHECKLIST, {practice: user.practice}],
            [USE, CUSTOMCHECKLIST, {practice: user.practice}],
            [USE, EDITLOCKEDINVOICE],
            [USE, REMOVEFROMINCOMPLETE],
            [USE, CUSTOMREPORT, {practice: user.practice}],
            [USE, MERGE, {practice: user.practice}],
            [USE, CUSTOMARTICLES, {practice: user.practice}],
            [READ, RECENT_ACTIVITY, {user: user.practice}]
        ]
    }
});
