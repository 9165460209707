export const required = (val) => {
	if (val === 0) {
		return;
	}

	if (typeof val === 'string' && !val.trim()) {
		return 'required';
	}

	if (!val) {
		return 'required';
	}
};

export const equivalent = (values, message) => {
	return values.a === values.b ? undefined : message;
};

export const positiveNumber = (value) => {
    if (!value) {
        return undefined;
    } else if (isNaN(value)) {
		return 'must be a number';
	} else if (Number(value) < 0) {
		return 'must be a positive number';
	} else {
		return undefined;
	}
};

export const max = (value, maxNumber) => {
	if (isNaN(value)) {
		return 'must be a number';
	} else if (Number(value) > maxNumber) {
		return 'must be less than or equal to ' + maxNumber;
	} else return undefined;
};

export const integer = value => {
	if (isNaN(value)) {
		return 'must be a number';
	} else if (!Number.isInteger(Number(value))) {
		return 'must be a whole number';
	} else return undefined;
};

export const emailValidation = (val) => {
	if (val) {
		const parsed = val.split('@');
		const hostParse = parsed[1] ? parsed[1].split('.') : [];

		if (parsed.length !== 2 || hostParse.length < 2 || parsed[0].length === 0 || parsed[1].length === 0 || hostParse[0] === 0 || hostParse[1].length === 0) {
			return 'enter a valid email';
		}

		if (val.trim().includes(' ')) {
			return 'enter a valid email';
		}
	}
	return undefined;
};

export const phoneValidation = (val) => {
	if (val) {
		return val.length === 14 ? undefined : 'enter a valid phone number';
	}
	return undefined;
};

export const currency = (val) => {
	if (!val) return undefined;
	const number = RegExp('^[-]?([0-9]+(\\.[0-9]{2,2})?)$');
	if (!number.test(val)) {
		return 'must be currency such as 10.50 or -13';
	}
}

export const tooLong = (val, max = 250) => {
    if (!val) return undefined;
    if (val.length > max) return 'the maximum length allowed is ' + max + ' characters';
}
