import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import {authentication, personalization, practiceSettings} from './authentication/reducers';
import thunk from 'redux-thunk';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
	combineReducers({
        authentication: authentication,
		practiceSettings: practiceSettings,
		personalization: personalization
	}),
	composeEnhancer(applyMiddleware(thunk))
);
