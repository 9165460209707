import React, {Component} from 'react';
import {
    Button,
    Col,
    Container,
    ModalFooter,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Label,
} from 'reactstrap';
// noinspection ES6CheckImport
import {Form} from "informed";


import Spinner from "../../../utils/spinner";
import {WrappedText} from "../../../utils/formElements";
import {required} from "../../../utils/validation";
import {createTickNoScreenshot} from "../../Help/services";
import {error} from "../../../utils/tools";

/**
 * @param formState.errors.additionalInfo: string
 */

class Page500 extends Component {
    constructor (props) {
        super(props);

        this.state = {
            modal: false,
            tickCreated: false,
            networkError: false
        };

        this.isCancelled = false;
        this.submitting = false;

        this.createTick = this.createTick.bind(this);
    }

    componentDidMount () {
        if (this.props.error) {
            try {
                const parsed = (JSON.parse(this.props.error));
                if (parsed.message && parsed.message === 'Network Error') {
                    !this.isCancelled && this.setState({networkError: true});
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                window.console && console.log('unable to parse error.  no action required');
            }
        }
    }

    componentWillUnmount () {
        this.isCancelled = true;
    }

    createTick (val) {
        if (this.submitting) {
            return;
        }

        this.submitting = true;

        const newTick = {
            type: 'bug',
            description: val.description + '\n\nSteps To Reproduce: ' + val.additionalInfo,
            url: window.location.href,
            systemError: this.props.error
        };

        createTickNoScreenshot(newTick)
            .then(() => {
                !this.isCancelled && this.setState({modal: false, tickCreated: true});
                this.submitting = false;
            })
            .catch(err => {
                error(err);
                this.submitting = false;
            });
    }


    render () {
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="6">
							<span className="clearfix">
								<h1 className="float-left display-3 mr-4">500</h1>
                                {this.state.networkError ?
                                    <div>
                                        <h4 className="pt-3">Network Connection Error</h4>
                                        <p className="text-muted">
                                            Refresh your browser once your connection has been restored or
                                            stabilized to continue using Buddy.
                                        </p>
                                    </div>
                                    :
                                    <>
                                        <h4 className="pt-3">Uh Oh! Something went wrong.</h4>
                                        <div>
                                            {this.state.tickCreated ?
                                                <span className="text-muted">
                                                    This error has been reported.  You may refresh the page or navigate away.
                                                </span>
                                                :
                                                <>
                                                    <p className="text-muted">
                                                        {this.props.message && this.props.message !== 500 ?
                                                            this.props.message :
                                                            <React.Fragment>
                                                                Please try again and if that doesn't work, <a
                                                                href="mailto:support@dvmcenter.com">contact us.</a>
                                                            </React.Fragment>
                                                        }
                                                    </p>
                                                    <Button
                                                        color="primary"
                                                        type="button"
                                                        onClick={() => !this.isCancelled && this.setState({modal: true})}
                                                    >
                                                        Report The Problem
                                                    </Button>
                                                </>

                                            }
                                        </div>
                                    </>
                                }

							</span>

                        </Col>
                    </Row>
                </Container>
                <Modal isOpen={this.state.modal} toggle={() => !this.isCancelled && this.setState({modal: false})}>
                    <ModalHeader toggle={() => !this.isCancelled && this.setState({modal: false})}>
                        Submit a TICK
                    </ModalHeader>
                    <Form onSubmit={this.createTick}>
                        {({formState}) =>
                            <>
                                <ModalBody>

                                    <ModalBody>
                                        {this.state.modalLoading ?
                                            <Spinner/>
                                            :
                                            <>
                                                <FormGroup>
                                                    <Label htmlFor="description">Description<span
                                                        className="text-danger">*</span></Label>
                                                    <WrappedText
                                                        field="description"
                                                        validate={required}
                                                        type="textarea"
                                                        initialValue="500 error screen."
                                                    />
                                                    <div
                                                        className="small text-danger">{formState.errors.description}</div>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label htmlFor="additionalInfo">
                                                        Additional Information
                                                        <span className="text-danger">*</span>
                                                    </Label>
                                                    <WrappedText
                                                        field="additionalInfo"
                                                        validate={required}
                                                        type="textarea"
                                                        placeholder="What were you doing when this bug happened? If you were working with a specific record, please include the client's name and the practice name."
                                                    />
                                                    <div
                                                        className="small text-danger">{formState.errors.additionalInfo}</div>

                                                </FormGroup>
                                            </>
                                        }
                                    </ModalBody>
                                </ModalBody>
                                <ModalFooter>
                                    <Button type="submit" color="primary">Submit</Button>
                                    <Button type="button" color="dark"
                                            onClick={() => !this.isCancelled && this.setState({modal: false})}>Cancel</Button>
                                </ModalFooter>
                            </>
                        }
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default Page500;
