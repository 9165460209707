const { AbilityBuilder, Ability } = require('@casl/ability');
const { permissions } = require('./permissions');

module.exports = (user) => {

	if (!user) {
		// apply role of guest in the event that user is undefined (aka -- not logged in)
		user = { uiuxRoles: ['guest'] };
	} else if (!user.uiuxRoles || (user.uiuxRoles && user.uiuxRoles.length === 0)) {
		// grant role of authenticated user by virture of being logged in
		user.uiuxRoles = ['authenticatedUser'];
	}

	const allRoles = user.uiuxRoles.map(el => el);
	const newRoles = [];

	user.uiuxRoles.forEach(role => {
		permissions(user)[role].inherits.forEach(inherited => {
			if (!allRoles.includes(inherited)) {
				allRoles.push(inherited);
				newRoles.push(inherited);
			}
		});
	});

	while (newRoles.length > 0) {
		permissions(user)[newRoles[0]].inherits.forEach(inherited => {
			if (!allRoles.includes(inherited)) {
				allRoles.push(inherited);
				newRoles.push(inherited);
			}
		});
		newRoles.shift();
	}

	user.uiuxRoles = allRoles;

	const { can, rules } = AbilityBuilder.extract();

	user.uiuxRoles.forEach(role => {
		permissions(user)[role].can.forEach(ability => {
			can(ability[0], ability[1], ability[2]);
        });
    });

	return new Ability(rules);
};
