import React from 'react';
import classnames from 'classnames';

class Spinner extends React.Component {
    render () {
        return (
            <>
            <div className={classnames({
                "sk-circle": true,
                "my-0": this.props['my-0'],
                "mb-0": this.props.message ? true : false
            })}>
                <div className="sk-circle1 sk-child"></div>
                <div className="sk-circle2 sk-child"></div>
                <div className="sk-circle3 sk-child"></div>
                <div className="sk-circle4 sk-child"></div>
                <div className="sk-circle5 sk-child"></div>
                <div className="sk-circle6 sk-child"></div>
                <div className="sk-circle7 sk-child"></div>
                <div className="sk-circle8 sk-child"></div>
                <div className="sk-circle9 sk-child"></div>
                <div className="sk-circle10 sk-child"></div>
                <div className="sk-circle11 sk-child"></div>
                <div className="sk-circle12 sk-child"></div>
            </div>
            <div className={classnames({
                "text-center": true,
                "mb-4": !this.props['my-0'],
            })}>{this.props.message}</div>
            </>
        );
    }
}

export default Spinner;