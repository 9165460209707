import React from 'react';
import {asField} from 'informed';
import {Input, FormGroup, Label} from 'reactstrap';
import classnames from 'classnames';
import Autosuggest from 'react-autosuggest';
import enGb from 'date-fns/locale/en-GB';
import DatePicker, {registerLocale} from 'react-datepicker';


registerLocale('en-gb', enGb);


export const exampleImplementation = () => {

    const myOnChangeFunction = value => { // takes single argument of value
        window.console && console.log(value);
    }

    const myOnBlurFunction = value => { // takes single argument of value
        window.console && console.log(value);
    }

    const formState = { // normally this component will be wrapped up in formState for control
        errors: {
            example1: 'example error statement'
        }
    }

    const options = [ // takes an array with value and text properties.  They must be strings.
        {value: 'value1', text: 'option 1'},
        {value: 'value2', text: 'option 2'},
        {value: 'value3', text: 'option 3'},
    ]


    return (
        <div>
            {/* Example Text Box with options */}
            <FormGroup>
                <Label htmlFor="example1">Example 1</Label>
                <WrappedText
                    type={"textarea" /* any HTML5 option supported, if not provided defaults to "text" */}
                    field="example1"
                    id="example1"
                    rows={2 /* specifies number of rows in the case of a textarea type field */}
                    placeholder="I am an example"
                    onChange={myOnChangeFunction}
                    onBlur={myOnBlurFunction}
                    disabled={false /* defaults to false */}
                    validateOnChange={true /* defaults to false */}
                    valOnBlur={true /* this IS Custom!!, defaults to false */}
                    mask={null /*function that runs during onchange*/}
                />
                <div className="invalid-feedback">{formState.errors.example1}</div>
            </FormGroup>

            {/* Example Radiogroup with options */}
            <Label htmlFor="example2">Example 2</Label>
            <WrappedRadioGroup
                field="example2"
                id="example2"
                onChange={myOnChangeFunction}
                onBlur={myOnBlurFunction}
                options={options}
                inline={true /* defaults to false */}
            />
            <small className="text-danger">{formState.errors.example2}</small>
            <FormGroup>
                <Label htmlFor="example3">Example 3</Label>
                <WrappedDropdown
                    field="example3"
                    id="example3"
                    options={options}
                    initialValue={options[0].value}
                    onChange={myOnChangeFunction}
                    onBlur={myOnBlurFunction}
                />
            </FormGroup>
            <FormGroup check>
                <Label check>
                    <WrappedCheckbox
                        field="active"
                        id="active"
                        initialValue={this.state.updateModal.practice.active}
                    />
                    Active?
                </Label>
                <div className="invalid-feedback">{formState.errors.active}</div>
            </FormGroup>
        </div>
    );
}


export const WrappedText = asField(({fieldState, fieldApi, ...props}) => {

    const {setValue, setTouched} = fieldApi;

    const onChange = e => {
        if (e.target.value) {
            setValue(e.target.value);
        } else {
            setValue('');
        }
        if (props.onChange) {
            props.onChange(e.target.value);
        }
    }

    const onBlur = e => {
        setTouched();
        if (props.onBlur) {
            props.onBlur(e.target.value);
        }

        if (props.valOnBlur) {
            fieldApi.validate();
        }
    }

    return (
        <Input
            onChange={onChange}
            onBlur={onBlur}
            className={classnames({
                'is-invalid': fieldState.error
            })}
            rows={props.rows}
            type={props.type}
            placeholder={props.placeholder}
            disabled={props.disabled}
            value={fieldState.value ? fieldState.value : ''}
            style={{width: props.width, ...props.style}}
            maxLength={props.maxLength}
            hidden={props.hidden}
            autoFocus={!!props.autoFocus}
        />
    );
});

export const WrappedRadioGroup = asField(({fieldState, fieldApi, ...props}) => {

    const {setValue, setTouched} = fieldApi;

    const onChange = e => {
        if (e.target.value) {
            setValue(e.target.value.split('**')[0]);
        } else {
            setValue('');
        }
        if (props.onChange) {
            props.onChange(e.target.value.split('**')[0]);
        }
    }

    const onBlur = e => {
        setTouched();
        if (props.onBlur) {
            props.onBlur(e.target.value.split('**')[0]);
        }
    }


    return (
        <div>
            {props.options.map(el =>
                <FormGroup key={el.value + '**' + props.field} check inline={props.inline} className="radio">
                    <Input
                        type="radio"
                        id={el.value + '**' + props.field}
                        name={props.field}
                        value={el.value + '**' + props.field}
                        checked={fieldState.value === el.value}
                        onChange={onChange}
                        onBlur={onBlur}
                        className={classnames({
                            'is-invalid': fieldState.error,
                            'form-check-input': true
                        })}
                        disabled={props.disabled}
                    />
                    <Label check className="form-check-label" htmlFor={el.value + '**' + props.field}>{el.text}</Label>
                </FormGroup>
            )}
        </div>
    );
});

export const WrappedDropdown = asField(({fieldState, fieldApi, ...props}) => {

    const {setValue, setTouched} = fieldApi;

    const onChange = e => {
        if (e.target.value) {
            setValue(e.target.value.split('**')[0]);
        } else {
            setValue('');
        }
        if (props.onChange) {
            props.onChange(e.target.value.split('**')[0]);
        }
    }

    const onBlur = e => {
        setTouched();
        if (props.onBlur) {
            props.onBlur(e.target.value.split('**')[0]);
        }

        if (props.valOnBlur) {
            fieldApi.validate();
        }
    }

    return (
        <Input
            type="select"
            id={props.field}
            name={props.field}
            className={classnames({
                'is-invalid': fieldState.error
            })}
            style={props.style}
            onChange={onChange}
            onBlur={onBlur}
            value={fieldState.value + '**' + props.field}
            disabled={props.disabled}
            autoFocus={!!props.autoFocus}
        >
            {props.options.map(el =>
                <option
                    key={el.value + '**' + props.field}
                    value={el.value + '**' + props.field}
                    disabled={!!el.disabled}
                >
                    {el.text}
                </option>
            )}
        </Input>
    );
});

export const WrappedCheckbox = asField(({fieldState, fieldApi, ...props}) => {

    const {setValue, setTouched} = fieldApi;

    const onChange = e => {
        if (e.target.checked) {
            setValue(e.target.checked);
        } else {
            setValue(false);
        }
        if (props.onChange) {
            props.onChange(e.target.checked);
        }

        if (props.valOnChange) {
            fieldApi.validate();

        }
    }

    const onBlur = e => {
        setTouched();
        if (props.onBlur) {
            props.onBlur(e.target.value);
        }

        if (props.valOnBlur) {
            fieldApi.validate();
        }
    }

    return (
        <Input
            type="checkbox"
            onChange={onChange}
            onBlur={onBlur}
            className={classnames({
                'text-danger': fieldState.error
            })}
            checked={fieldState.value || false}
            disabled={props.disabled}
        />
    );
});

// takes and returns a Date object (displays date in browser local time and must be converted on submit)
export const WrappedDatepicker = asField(({fieldState, fieldApi, ...props}) => {

    const {setValue, setTouched} = fieldApi;

    const onChange = date => {
        setValue(date);
        if (props.onChange) {
            props.onChange(date);
        }
    }

    const onBlur = () => { // e.target.value here will make a string, not the Date object
        setTouched();
        if (props.onBlur) {
            props.onBlur(fieldState.value);
        }

        if (props.valOnBlur) {
            fieldApi.validate();
        }
    }

    return (
        <div>
            <DatePicker
                selected={fieldState.value}
                onChange={onChange}
                className={classnames({
                    'is-invalid': fieldState.error,
                    'form-control': true
                })}
                onBlur={onBlur}
                dateFormat="M/d/yyyy"
                placeholderText={props.placeholder}
                locale="en-gb"
                openToDate={props.openToDate}

            />
        </div>
    );
});

class BasicAutosuggest extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            suggestions: []
        }

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    }

    onChange = (e, {newValue}) => {
        this.props.onChange(newValue);
    }

    onBlur = (e, {highlightedSuggestion}) => {
        const value = e.target.value;
        this.props.onBlur(value);


        // automatically pick something on blur
        if (this.props.selectionRequired) {
            if (highlightedSuggestion) {
                this.props.onSuggestionSelected && this.props.onSuggestionSelected(null, {suggestion: highlightedSuggestion});
                this.props.onChange(highlightedSuggestion.text);
            } else {

                this.props.getSuggestions(value, (res) => {
                    // handle blur event when no suggestions are displayed

                    if (res && res[0]) {
                        // pick the exact match if it exists
                        const exactMatch = res.find(el => el.text === value);
                        if (exactMatch) {
                            this.props.onChange(exactMatch.text);
                            this.props.onSuggestionSelected && this.props.onSuggestionSelected(null, {suggestion: exactMatch});
                        } else {
                            // pick the first one if no exact match
                            this.props.onChange(res[0].text);
                            this.props.onSuggestionSelected && this.props.onSuggestionSelected(null, {suggestion: res[0]});
                        }
                    } else {
                        // blank it out if there are no results at all
                        this.props.onChange('');
                        this.props.onSuggestionSelected && this.props.onSuggestionSelected(null, {suggestion: {}});
                    }
                });
            }
        }
    }

    onSuggestionsFetchRequested = ({value}) => {
        this.props.getSuggestions(value, (res) => {
            !this.isCancelled && this.setState({
                suggestions: res.slice(0, 7)
            });
        });
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    }

    theme () {
        return {
            container: this.props.containerClass ? this.props.containerClass : 'react-autosuggest__container',
            containerOpen: 'react-autosuggest__container--open',
            input: 'react-autosuggest__input',
            inputOpen: 'react-autosuggest__input--open',
            inputFocused: 'react-autosuggest__input--focused',
            suggestionsContainer: 'react-autosuggest__suggestions-container',
            suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
            suggestionsList: 'react-autosuggest__suggestions-list',
            suggestion: 'react-autosuggest__suggestion',
            suggestionFirst: 'react-autosuggest__suggestion--first',
            suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
            sectionContainer: 'react-autosuggest__section-container',
            sectionContainerFirst: 'react-autosuggest__section-container--first',
            sectionTitle: 'react-autosuggest__section-title'
        };
    }

    render () {
        const {value} = this.props;
        const {suggestions} = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: this.props.placeholder,
            value,
            onChange: this.onChange,
            onBlur: this.onBlur,
            className: classnames({'form-control': true, 'is-invalid': this.props.invalid}),
            disabled: this.props.disabled,
        };

        const renderSuggestion = (suggestion) => {
            if (this.props.renderSuggestion) {
                return this.props.renderSuggestion(suggestion);
            }

            const value = this.props.getSuggestionValue(suggestion);

            return (
                <span>
					{value}
				</span>
            );
        };

        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.props.getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                onSuggestionSelected={this.props.onSuggestionSelected}
                highlightFirstSuggestion
                theme={this.theme()}
            />

        );
    }
}

// selectionRequired -- behaves like a dropdown in that a suggestion must be selected and free text is not allowed.  False if ommitted
export const WrappedAutosuggest = asField(({fieldState, fieldApi, ...props}) => {

    const {value} = fieldState;
    const {setValue, setTouched} = fieldApi;

    const onChange = (newVal) => {
        if (newVal) {

            setValue(newVal);
        } else {
            setValue('');
        }

        if (props.onChange) {
            props.onChange(newVal.trim());
        }
    };

    const onBlur = (newVal) => {
        setTouched();
        if (props.onBlur) {
            props.onBlur(newVal);
        }

        if (props.valOnBlur) {
            fieldApi.validate();
        }
    }

    return (
        <BasicAutosuggest
            value={value ? value : ''}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={props.placeholder}
            invalid={!!fieldState.error}
            name={props.id}
            getSuggestionValue={props.getSuggestionValue}
            getSuggestions={props.getSuggestions}
            renderSuggestion={props.renderSuggestion}
            onSuggestionSelected={props.onSuggestionSelected}
            setValue={setValue}
            style={props.style}
            disabled={props.disabled}
            containerClass={props.containerClass}
            selectionRequired={props.selectionRequired}
        />
    );
});
